import { FC } from 'react'
import { Spin, message, Typography } from 'antd'
import _ from 'lodash'

import StyledContainer from '@root/ic-ui-react/StyledContainer'
import useFetchDishes from '@ic-api/menu/useFetchDishes'
import Dish from '@models/dish'
import Layout from '@root/components/Layout'
import CategoryView from '@root/components/menu/CategoryView'

const SpecialMenu: FC = () => {
  const { loading, dishes, error } = useFetchDishes()

  return loading ? (
    <Spin size="large" tip="LOADING..." style={{ display: 'block', marginLeft: 'auto' }} />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <>{dishes && dishes.length > 0 && <MenuDisplay dishes={dishes} />}</>
      )}
    </>
  )
}

const MenuDisplay: FC<{ dishes: Dish[] }> = ({ dishes }) => (
  <StyledContainer fluid>
    <Layout>
      <StyledContainer fluid relax>
        <Typography.Title>MONTHLY SPECIAL</Typography.Title>
        <Typography.Paragraph>
          Please call <Typography.Link href="tel:5624240446">(562) 424-0446 </Typography.Link>
          to order.
        </Typography.Paragraph>
      </StyledContainer>

      <StyledContainer fluid>
        <CategoryView dishes={_.filter(dishes, { category: 'Monthly Special' })} />
      </StyledContainer>
    </Layout>
  </StyledContainer>
)

export default SpecialMenu
